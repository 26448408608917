import React, {useCallback, useEffect, useState} from 'react'
import {HealthAreaSection, getStatusSexualHealth} from './HealthAreaSection'
import MobileHealthArea from './MobileHealthArea'
import {HorizontalSection} from 'src/shared/HorizontalSection'
import {Loading} from 'reactstrap-toolbox'
import {format_date, iconHelper} from 'src/utils'
import {DoctorLetter} from './DoctorLetter'
import './BloodReportPage.scss'
import drDaveNichols from 'src/assets/images/doctors/Dave_Nichols.jpg'
import drGavinRalston from 'src/assets/images/doctors/Gavin_Ralston.jpg'
import drIcon from 'src/assets/images/Doctor.svg'
import {DoctorLetterModal} from './DoctorLetterModal'
import {Button} from 'reactstrap'

const doctorPictures = {
  'Dave Nichols': drDaveNichols,
  'Gavin Ralston': drGavinRalston,
}

export function getDoctorImg(name) {
  if (doctorPictures[name]) {
    return doctorPictures[name]
  }

  return
}

function getAreaStatus(health_area) {
  if (String(health_area.area).toLowerCase() !== 'sexual health') {
    return health_area.area_status
  }

  return getStatusSexualHealth(health_area.markers)
}

const DoctorNotesSection = 'doctor_notes'

// interface Props extends RouteComponentProps<{ id: string }> {}

function BloodReportPage({match}) {
  const [report, setReport] = useState()
  const [loading, setLoading] = useState(false)
  const [currentSection, setCurrentSection] = useState(DoctorNotesSection)
  const [letterModalVisible, setLetterModalVisibility] = useState(false)

  function setSection(name) {
    setCurrentSection(name)
  }
  const {id} = match.params

  const loadReport = useCallback(async () => {
    setLoading(true)
    const result = await window.app.requests.get(`/api/lims/test-kits/report/${id}/`)
    window.app.setTitle(result.data.name)
    console.log(result.data)
    setReport(result.data.data)
    setLoading(false)
  }, [id])

  useEffect(() => {
    loadReport()
  }, [loadReport])

  if (loading || !report) {
    return <Loading />
  }

  const showDrLetter = () => {
    setSection(DoctorNotesSection)
    const isSmallScreen = window.innerWidth < 700

    if (isSmallScreen) {
      setLetterModalVisibility(!letterModalVisible)
    }
  }

  const renderDoctorLetter = () => (
    <DoctorLetter
      doctor={report.assigned_doctor}
      patient={report.customer}
      overallStatus={report.overall_status}
      testName={report.title}
      content={report.doctors_letter || ''}
      missingAssessment={!report.assessment_id}
    />
  )

  let navItems = []

  navItems.push({
    name: DoctorNotesSection,
    label: `Doctor's letter`,
    icon: drIcon,
    background: '#F9EFE2',
    content: renderDoctorLetter,
  })

  if (report.health_areas.length === 1) {
    navItems.push({
      area: {...report.health_areas[0], area: 'Your results'},
      name: `${report.report_type}`,
      label: 'Your results',
      status: getAreaStatus(report.health_areas[0]),
      icon: iconHelper(`Biomarker ${report.title}`),
      content: () => <HealthAreaSection {...report.health_areas[0]} area={report.title} type={report.report_type} />,
    })
  } else {
    report &&
      report.health_areas.forEach(area => {
        navItems.push({
          area: area,
          name: area.area,
          label: area.area,
          status: getAreaStatus(area),
          icon: iconHelper(`Biomarker ${area.area}`),
          content: () => <HealthAreaSection {...area} type={report.report_type} />,
        })
      })

    let nonDoc = navItems.splice(1)

    if (nonDoc) {
      nonDoc.sort((a, b) => a.name.localeCompare(b.name))
    }

    navItems = navItems.concat(nonDoc || [])
  }

  const mobileItems = navItems.filter(item => item.name !== DoctorNotesSection)

  const testIcon = iconHelper(report.title)

  return (
    <section className="bloodReport__container">
      <header className="bloodReport__header">
        {testIcon && (
          <div className="bloodReport__header-icon">
            <img src={testIcon} alt={report.title} />
          </div>
        )}
        <div className="bloodReport__header-main">
          <h1>{report.title}</h1>
          <p className="bloodReport__header-summary">Welcome {report.customer.split(' ')[0]}!</p>
          {report.activated_at && (
            <p className="bloodReport__header-details">
              Date Test Taken: <strong>{format_date(report.activated_at)}</strong>
            </p>
          )}
        </div>

        <aside className="bloodReport__header-sidebar">
          {getDoctorImg(report.assigned_doctor) && (
            <img src={getDoctorImg(report.assigned_doctor)} alt={report.assigned_doctor} />
          )}
          <div className="bloodReport__sidebar-button-main">
            <p>Analysed by</p>
            <h2>Dr {report.assigned_doctor}</h2>

            <Button onClick={showDrLetter} color="primary">
              read <span className="bloodReport__sidebar-button-extra-copy">doctor's </span>letter
            </Button>
          </div>
        </aside>
      </header>

      <div className="bloodReport__main--desktop">
        <HorizontalSection items={navItems} setCurrentItem={setSection} current={currentSection} />
      </div>

      <div className="bloodReport__main--mobile">
        {mobileItems.map((item, key) => (
          <MobileHealthArea
            key={key}
            {...(item.area !== null)}
            type={report.report_type}
            expanded={mobileItems.length === 1}
            collapsible={mobileItems.length !== 1}
          />
        ))}
      </div>

      <DoctorLetterModal title={`Letter from doctor`} isOpen={letterModalVisible} toggle={showDrLetter}>
        {renderDoctorLetter()}
      </DoctorLetterModal>
    </section>
  )
}

export default BloodReportPage
