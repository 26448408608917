import React, {useState} from 'react'
import {Button} from 'reactstrap'
import {faCheck, faExclamation} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {BloodMarkerModal} from './BloodMarkerModal'
import {MarkerScale} from './MarkerScale'
import './BloodMarkerCard.scss'
import {getStatusSexualHealth} from './HealthAreaSection'

function BloodMarkerCard({marker}) {
  const [modalOpened, setModal] = useState(false)
  const toggleModal = () => setModal(!modalOpened)

  return (
    <div className={`markerCard__container markerCard__${marker.type}`}>
      {marker.type === 'negative-positive' ? (
        <PositiveNegativeCard marker={marker} toggleModal={toggleModal} />
      ) : (
        <GenericMarkerCard marker={marker} toggleModal={toggleModal} />
      )}

      <BloodMarkerModal marker={marker} isOpen={modalOpened} toggle={toggleModal} />
    </div>
  )
}

function GenericMarkerCard({marker, toggleModal}) {
  const markerValue = typeof marker.value === 'number' ? marker.value.toFixed(2) : marker.value
  let statusIcon = faExclamation
  let status = marker.result

  if (['normal', 'green'].indexOf(marker.result) !== -1) {
    statusIcon = faCheck
    status = 'normal'
  }

  return (
    <>
      <header className="markerCard__header">
        <span className={`markerCard__header-status markerCard__header-status--${status}`}>
          <FontAwesomeIcon icon={statusIcon} />
        </span>
        <h2 className="markerCard__title" id={`tooltip-${marker.code}`}>
          {marker.name}
          <small className="">({marker.code})</small>
        </h2>

        {marker.type !== 'negative-positive' && (
          <div className="markerCard__value">
            {parseFloat(markerValue).toFixed(2)}{' '}
            {marker.units && <span className="markerCard__unit">{marker.units}</span>}
          </div>
        )}
      </header>

      <div className="markerCard__content">
        <div className="markerCard__scale">
          <MarkerScale {...marker} />
        </div>
        <Button className="markerCard__button" onClick={toggleModal} size="sm">
          Read more
        </Button>
      </div>
    </>
  )
}

function PositiveNegativeCard({marker, toggleModal}) {
  let status = getStatusSexualHealth([marker])
  let statusIcon = status === 'normal' ? faCheck : faExclamation

  function toTitleCase(str) {
    return str.replace(/\b(\w)/g, k => k.toUpperCase())
  }

  return (
    <>
      <div className="markerCard__content">
        <h2 className="markerCard__title" id={`tooltip-${marker.code}`}>
          {marker.name}
        </h2>

        <div className="markerCard__result_container">
          <span className={`markerCard__header-status markerCard__header-status--${status}`}>
            <FontAwesomeIcon icon={statusIcon} />
          </span>
          {toTitleCase(marker.value.toString().replaceAll('**', '') || '')}
        </div>

        <Button className={`markerCard__button markerCard__button--${status}`} onClick={toggleModal} size="sm">
          What does this mean?
        </Button>
      </div>
    </>
  )
}

export {BloodMarkerCard, GenericMarkerCard, PositiveNegativeCard}
