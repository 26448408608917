import React from 'react'
import {field_title, format_val} from 'src/utils'

export default ({details, item}) =>
  details.map((col, i) => (
    <div key={i} className="detail">
      <div>{field_title(col)}:</div>
      <div>{format_val(item, col)}</div>
    </div>
  ))
