import React from 'react'

export default (value, timeout) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), timeout)
    return () => clearTimeout(handler)
  }, [value, timeout])

  return debouncedValue
}
