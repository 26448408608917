import React from 'react'
import {Link} from 'react-router-dom'
import {Col, Row} from 'reactstrap'
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons'
import {message_toast} from 'reactstrap-toolbox'
import * as Sentry from '@sentry/react'
import logo from 'src/assets/images/mhc.svg'
import Flag from './Flag'
import Router from './Router'
import Menu from './Menu'
import Search from './Search'
import MfaPrompt from './MfaPrompt'

export default ({error, authenticate_sentinel}) => {
  const [menu, setMenu] = React.useState(null)
  const [user, setUser] = React.useState(null)
  const [canSearch, setCanSearch] = React.useState(false)
  const [panels, setPanels] = React.useState(null)

  React.useEffect(() => {
    window.app.requests.get('/api/lims/').then(r => {
      if (r.data.user) {
        setUser(r.data.user)
        Sentry.setUser(r.data.user)
        setMenu(r.data.menu)
        setCanSearch(r.data.can_search)
        setPanels(r.data.panels)
      } else {
        setUser(false)
        setMenu(null)
        setPanels(null)
        Sentry.configureScope(s => s.setUser(null))
        if (r.data.auth_error === 'session-expired') {
          message_toast({icon: faSignInAlt, title: 'Log in', message: 'Session expired, please login'})
        }
      }
    })
  }, [authenticate_sentinel])

  const clearMenu = React.useCallback(() => {
    setMenu(null)
    setCanSearch(false)
    setPanels(null)
  }, [])

  return (
    <>
      <Flag />
      <main>
        <div className="d-flex flex-row justify-content-between align-items-start">
          <div className="top">
            <Link to="/">
              <img src={logo} alt="MyHealthChecked" />
            </Link>
          </div>
          {user && canSearch ? <Search className="d-none d-md-block" /> : null}
          <div>
            <Menu className="d-lg-none" menu={menu} />
          </div>
        </div>
        <Row>
          <Col lg={3} xl={2}>
            <div className="std-top d-none d-lg-block">
              <Menu menu={menu} />
            </div>
          </Col>
          <Router error={error} user={user} clearMenu={clearMenu} panels={panels} />
        </Row>
        <MfaPrompt user={user} />
      </main>
    </>
  )
}
