import React from 'react'

export default ({color, leftLabel, rightLabel, percentage, steps = 4, children, text_margin = 10}) => {
  percentage = Math.max(0, Math.min(percentage, 100))
  const text_percentage = Math.max(text_margin, Math.min(percentage, 100 - text_margin))
  return (
    <div className="scale">
      <div className={`bar inactive bg-${color}`} />
      <div className={`bar active bg-${color}`} style={{width: `${percentage}%`}} />
      <div className="lines">
        {[...Array(steps - 1)].map((_, i) => (
          <div key={i} />
        ))}
      </div>
      <div className={`circle bg-${color}`} style={{left: `${percentage}%`}} />
      <div className="labels-container">
        {leftLabel && <span className="scale-label left">{leftLabel}</span>}
        {rightLabel && <span className="scale-label right">{rightLabel}</span>}
        <div className={`text`} style={{left: `${text_percentage}%`}}>
          {children}
        </div>
      </div>
    </div>
  )
}
