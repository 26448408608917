import React from 'react'
import {Progress} from 'reactstrap'

import './MarkerScale.scss'

const lowBoundaryTypes = ['low-normal', 'low-normal-high']
const highBoundaryTypes = ['normal-high', 'low-normal-high', 'negative-positive']

function isSingleBoundaryScale(params) {
  return params.type === 'low-normal' || params.type === 'normal-high'
}

function isNegativePositiveScale(params) {
  return params.type === 'negative-positive'
}

function trimToRange(value, min, max) {
  if (value < min) {
    return min
  }

  if (value > max) {
    return max
  }

  return value
}

function getScaleDetails(allParams) {
  let params = {...allParams}

  if (params.decision_type === 'female-manual') {
    params.lower_boundary = 2
    params.upper_boundary = 4

    params.value = 3
    if (params.result === 'low') {
      params.value = 1
    } else if (params.result === 'normal') {
      params.value = 3
    } else if (params.result === 'high') {
      params.value = 5
    }
  }

  let barSize = 50
  let leftBarSize = 0

  if (isNegativePositiveScale(params)) {
    return {
      pointerPosition: params.result === 'green' ? 25 : 75,
      barSize: 50,
    }
  }

  if (isSingleBoundaryScale(params)) {
    let {value, boundary} = params
    const pointerPosition = (value / boundary / 2) * 100

    return {
      pointerPosition: trimToRange(pointerPosition, 0, 100),
      barSize,
      leftBarSize,
    }
  }

  let {lower_boundary, upper_boundary, value} = params
  let delta = upper_boundary - lower_boundary
  let min = lower_boundary - delta
  if (min < 0) min = 0

  let max = upper_boundary + delta

  leftBarSize = ((lower_boundary - min) / (max - min)) * 100
  barSize = (100 - leftBarSize) / 2

  const pointerPosition = ((value - min) / (max - min)) * 100

  return {
    pointerPosition: trimToRange(pointerPosition, 2, 98),
    barSize,
    leftBarSize,
  }
}

export function MarkerScale(props) {
  const {value, type} = props

  const showLowBoundary = lowBoundaryTypes.includes(type)
  const showHighBoundary = highBoundaryTypes.includes(type)
  const isBoolMarker = type === 'negative-positive'
  const normalBoundaryLabel = isBoolMarker ? 'Not detected' : 'Normal'
  let highBoundaryLabel = isBoolMarker ? 'Detected' : 'High'

  if (String(value).toLowerCase() === 'unconfirmed reactive') {
    highBoundaryLabel = 'Unconfirmed Reactive'
  }

  const {barSize, pointerPosition, leftBarSize} = getScaleDetails(props)

  function renderRangeLabel() {
    if (props.decision_type === 'female-manual') return null

    if (props.type === 'low-normal-high') {
      if (!props.lower_boundary || !props.upper_boundary) {
        return `${props.lower_boundary} - ${props.upper_boundary}`
      } else {
        return `${props.lower_boundary.toFixed(2)} - ${props.upper_boundary.toFixed(2)}`
      }
    }

    if (props.type === 'low-normal') {
      if (!props.boundary) {
        return `> ${props.boundary}`
      } else {
        return `> ${props.boundary.toFixed(2)}`
      }
    }

    if (props.type === 'normal-high') {
      if (!props.boundary) {
        return `< ${props.boundary}`
      } else {
        return `< ${props.boundary.toFixed(2)}`
      }
    }
  }

  return (
    <div className="markerScale__container">
      <div className="markerScale__pointer" style={{left: `${pointerPosition}%`}}></div>

      <Progress className="my-0 markerScale__bar-container" multi>
        {showLowBoundary && (
          <>
            <Progress
              className="mr-0 markerScale__bar markerScale__bar--bad"
              bar
              value={leftBarSize || barSize}
            ></Progress>
          </>
        )}

        <Progress className="mx-0 markerScale__bar" bar value={barSize}></Progress>

        {showHighBoundary && (
          <Progress
            className={`ml-0 markerScale__bar markerScale__bar--${
              value === 'Unconfirmed Reactive' ? 'unconfirmed' : 'bad'
            }`}
            bar
            value={barSize}
          ></Progress>
        )}
      </Progress>
      <div className="markerScale__labels">
        {showLowBoundary && (
          <div style={{width: `${leftBarSize || barSize}%`}} className="markerScale__label">
            Low
          </div>
        )}
        <div style={{width: `${barSize}%`}} className="markerScale__label">
          {normalBoundaryLabel}
          <div className="markerScale__label-range">{renderRangeLabel()}</div>
        </div>
        {showHighBoundary && (
          <div style={{width: `${barSize}%`}} className="markerScale__label">
            {highBoundaryLabel}
          </div>
        )}
      </div>
    </div>
  )
}
