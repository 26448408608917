import React from 'react'
import moment from 'moment'
import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  UncontrolledCollapse,
  Input,
  Table,
  Form,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap'
import {Link} from 'react-router-dom'
import {faCheck, faTimes, faAngleDown} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ReactMarkdown from 'react-markdown'
import {as_title} from 'reactstrap-toolbox'
import classNames from 'classnames'

//DNA test icons
import Glucose from 'src/assets/icons/Tests/DNA_Glucose_Management.svg'
import Heart from 'src/assets/icons/Tests/DNA_Heart_Profile.svg'
import Intolerances from 'src/assets/icons/Tests/DNA_Intolerances.svg'
import Vitamins from 'src/assets/icons/Tests/DNA_Vitamins.svg'
import Weight from 'src/assets/icons/Tests/DNA_Weight_Management.svg'

// Blood test icons
import Cholesterol from 'src/assets/icons/Tests/Blood_Cholesterol.svg'
import Energy_Profile from 'src/assets/icons/Tests/Blood_Energy_Profile.svg'
import Female_Sexual_Health from 'src/assets/icons/Tests/Blood_Female_Sexual_Health.svg'
import General_Health_Test from 'src/assets/icons/Tests/Blood_General_Health_Test.svg'
import Heart_Profile from 'src/assets/icons/Tests/Blood_Heart_Profile.svg'
import Male_Sexual_Health from 'src/assets/icons/Tests/Blood_Male_Sexual_Health.svg'
import Menopause from 'src/assets/icons/Tests/Blood_Menopause.svg'
import PCOS from 'src/assets/icons/Tests/Blood_PCOS.svg'
import Thyroid from 'src/assets/icons/Tests/Blood_Thyroid.svg'
import Erectile_Dysfunction from 'src/assets/icons/Tests/Blood_Erectile.svg'
import Vitamins_and_Minerals from 'src/assets/icons/Tests/Blood_Vitamins_and_Minerals.svg'
import Weight_Management from 'src/assets/icons/Tests/Blood_Weight_Management.svg'

// Biomarkers icons
import Biomarker_Cholesterol from 'src/assets/icons/Biomarkers/Cholesterol.svg'
import Biomarker_Full_Blood_Count from 'src/assets/icons/Biomarkers/Full_Blood_Count.svg'
import Biomarker_Hormones from 'src/assets/icons/Biomarkers/Hormones.svg'
import Biomarker_Kidney from 'src/assets/icons/Biomarkers/Kidney.svg'
import Biomarker_Liver from 'src/assets/icons/Biomarkers/Liver.svg'
import Biomarker_Sexual_Health from 'src/assets/icons/Biomarkers/Sexual_Health.svg'
import Biomarker_Thyroid from 'src/assets/icons/Biomarkers/Thyroid.svg'
import Biomarker_Vitamins_and_Minerals from 'src/assets/icons/Biomarkers/Vitamins_and_Minerals.svg'

export const sub_keys = (s, item) => {
  let null_item = false
  const r = s.replace(/{(.+?)}/g, (_, key) => {
    const v = item[key]
    if (v === undefined) {
      throw Error(`field "${key}" not found in ${JSON.stringify(item)}`)
    }
    null_item |= v === null
    return v || ''
  })
  if (null_item) {
    return null
  }
  return r
}

export const format_val = (item, col) => {
  let value
  if (col.render) {
    value = sub_keys(col.render, item)
  } else if (col.field) {
    value = item[col.field]
    if (value === undefined) {
      throw Error(`field "${col.field}" not found in ${JSON.stringify(item)}`)
    }
  } else {
    throw Error('either "field" or "render" required')
  }
  const {link, fmt} = col
  if (link && value) {
    const to = sub_keys(link, item, false)
    if (to) {
      if (to.startsWith('http')) {
        return (
          <a href={to} target="_blank" rel="noopener noreferrer">
            {value}
          </a>
        )
      } else {
        return <Link to={to}>{basic_format_value(value, fmt)}</Link>
      }
    }
  }
  return basic_format_value(value, fmt)
}

export const field_title = col => col.title || as_title(col.field || (col.render || '').replace(/[{}]/g, ''))

const DTF = 'ddd Do MMM, h:mma'

export const format_date = ts => moment(ts).format('Do MMM YYYY')
export const format_dt_ago = ts => {
  const m = moment(ts)
  return `${m.format(DTF)} (${m.fromNow()})`
}
export const format_dt_age = dob => {
  const dobMoment = moment(dob)
  const currentMoment = moment()

  // Calculate the age
  const age = currentMoment.diff(dobMoment, 'years')

  return `${dob} (${age} years old)`
}
export const format_duration = interval_s => {
  const minutes = Math.round(interval_s / 60)
  return `${Math.floor(minutes / 60)} hours ${minutes % 60} minutes`
}
// To show date in status in proper format
export const format_status_datetime = status => {
  const value = moment(status.split('@')[1]).format(DTF)
  return `${status.split('@')[0]} @ ${value}`
}
function basic_format_value(value, fmt) {
  if (value === null || value === undefined) {
    return <span className="text-muted">&mdash;</span>
  } else if (typeof value === 'boolean' && !fmt) {
    const icon = value ? faCheck : faTimes
    return <FontAwesomeIcon icon={icon} />
  } else if (fmt === 'datetime') {
    return <span data-raw-value={value}>{format_dt_ago(value)}</span>
  } else if (fmt === 'date') {
    return format_date(value)
  } else if (fmt === 'dateage') {
    return format_dt_age(value)
  } else if (fmt === 'duration') {
    return format_duration(value)
  } else if (fmt === 'as-title') {
    return as_title(value)
  } else if (fmt === 'markdown') {
    return <ReactMarkdown>{value}</ReactMarkdown>
  } else if (fmt === 'json') {
    return <Json obj={JSON.parse(value)} />
  } else if (fmt === 'blood-report') {
    return <BloodReport obj={JSON.parse(value)} editable={false} restricted={false} />
  } else if (fmt === 'blood-report-cct') {
    return <BloodReport obj={JSON.parse(value)} editable={false} restricted={true} />
  } else if (fmt === 'blood-report-edit') {
    return <BloodReport obj={JSON.parse(value)} editable={true} restricted={false} />
  } else if (fmt === 'inline-code') {
    return <code className="inline-code">{value}</code>
  } else if (fmt === 'inline-code-datetime') {
    return <span>{format_status_datetime(value)}</span>
  } else if (fmt === 'money') {
    return `£${value.toFixed(2)}`
  } else if (fmt === 'html_iframe') {
    return <HtmlIframe value={value} />
  }

  if (fmt) {
    throw Error(`unknown fmt "${fmt}"`)
  }

  if (Array.isArray(value)) {
    return value.join(', ')
  }
  return value
}

const HtmlIframe = ({value}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [content, setContent] = React.useState(null)

  const toggle = () => setIsOpen(!isOpen)

  React.useEffect(() => {
    if (true === isOpen && !content) {
      fetch(value)
        .then(response => response.text())
        .then(data => {
          if (data) {
            setContent(data)
          }
        })
    }
  })

  // Hacky workaround to disable content within the iframe being clicked while still allowing scrolling
  const [frameElement, setFrameElement] = React.useState(null)
  const handleFrameElement = React.useCallback(e => {
    setFrameElement(e.target)
  }, [])
  React.useEffect(() => {
    if (frameElement?.contentDocument?.body !== undefined) {
      frameElement.contentDocument.body.style.pointerEvents = 'none'
    }
  }, [frameElement])

  return (
    <div>
      <iframe className="rounded border html-iframe" title="HTML" src={value} onLoad={handleFrameElement} />
      <Button size="sm" onClick={toggle}>
        {isOpen ? 'Hide' : 'Show'} Source
        <FontAwesomeIcon className={`ml-2 collapse-arrow ${isOpen ? 'down' : 'up'}`} icon={faAngleDown} />
      </Button>
      <Collapse isOpen={isOpen}>
        <div className="code-block mt-2">
          <pre>
            <code>{content}</code>
          </pre>
        </div>
      </Collapse>
    </div>
  )
}

const Json = ({obj}) => (
  <div className="code-block">
    <pre>
      <code>{JSON.stringify(obj, null, 2)}</code>
    </pre>
  </div>
)

const BloodReport = ({obj, editable, restricted}) => {
  const [formData, setFormData] = React.useState(obj)
  const [activeTab, setActiveTab] = React.useState('summary')
  const [savingChanges, setSavingChanges] = React.useState(false)
  const [showResult, setShowResult] = React.useState('')
  const [updatingText, setUpdatingText] = React.useState(false)
  const [updatingLetter, setUpdatingLetter] = React.useState(false)

  const markerResult = ['low', 'high', 'abnormal low', 'abnormal high', 'normal']
  const shPositive = ['detected', 'unconfirmed reactive', '**detected**']

  const originalLetter = obj.doctors_letter

  let submitForm = async e => {
    e.preventDefault()
    setSavingChanges(true)
    const r = await window.app.requests.post(`/api/lims/review-reports/report/${obj.test_kit_id}/`, formData, {
      expected_status: [200, 403, 422],
    })
    if (r.status === 200) {
      setShowResult('Saved!')
    } else {
      setShowResult('Error')
    }
    setTimeout(() => {
      setShowResult('')
    }, 1000)
    setSavingChanges(false)
  }

  const updateReport = async (event, field, area, marker, code) => {
    // 👇️ update textarea value
    let newFormData = {...formData}
    if (field === 'doctors_letter') {
      newFormData.doctors_letter = event.target.value
    }
    if (field === 'overall_status') {
      let result = event.target.value
      newFormData.overall_status = result
      //if not a sexual health test then update the doctor letter from the CMS
      if (!(formData.report_type === 'blood-male-sex-health' || formData.report_type === 'blood-female-sex-health')) {
        // eslint-disable-next-line default-case
        setUpdatingLetter(true)
        const r = await window.app.requests.get(`/api/lims/review-reports/doctors_letters/`)
        if (r.status === 200) {
          if (r.data.doctors_letters) {
            let active_letter = r.data.doctors_letters.find(letter => letter.status === result)
            if (active_letter) {
              let doctors_letter = active_letter['description']
              doctors_letter = doctors_letter.replaceAll('{{customer}}', formData.customer)
              doctors_letter = doctors_letter.replaceAll('{{title}}', formData.title)
              doctors_letter = doctors_letter.replaceAll('{{assigned_doctor}}', formData.assigned_doctor)
              newFormData.doctors_letter = doctors_letter
            } else {
              newFormData.doctors_letter = originalLetter
            }
          }
        } else {
          console.log('unable to get new text')
        }
        setUpdatingLetter(false)
      }
    }
    if (field === 'doctors_notes') {
      newFormData.health_areas[area].doctors_notes = event.target.value
    }
    if (field === 'area_status') {
      newFormData.health_areas[area].area_status = event.target.value
    }
    if (field === 'marker_result') {
      let result = event.target.value
      newFormData.health_areas[area].markers[marker].result = result
      setUpdatingText(true)
      const r = await window.app.requests.get(`/api/lims/review-reports/markertext/${code}/`)
      if (r.status === 200) {
        if (r.data.what_is) {
          newFormData.health_areas[area].markers[marker].details.what_is = r.data.what_is
        }
        if (r.data.what_can) {
          newFormData.health_areas[area].markers[marker].details.what_can = r.data.what_can
        }
        if (result === 'female-manual') {
          newFormData.health_areas[area].markers[marker].details.what_can = ''
          newFormData.health_areas[area].markers[marker].details.meaning = ''
          newFormData.health_areas[area].markers[marker].details.symptoms = ''
          newFormData.health_areas[area].markers[marker].details.actions = ''
          newFormData.health_areas[area].markers[marker].details.repeat = ''
        }
        if (result === 'Abnormal low') {
          if (r.data.low_meaning) {
            newFormData.health_areas[area].markers[marker].details.meaning = r.data.low_meaning
          }
          if (r.data.low_symptoms) {
            newFormData.health_areas[area].markers[marker].details.symptoms = r.data.low_symptoms
          }
          if (r.data.low_actions) {
            newFormData.health_areas[area].markers[marker].details.actions = r.data.low_actions
          }
          if (r.data.low_repeat) {
            newFormData.health_areas[area].markers[marker].details.repeat = r.data.low_repeat
          }
        }
        if (result === 'Normal') {
          if (r.data.normal_meaning) {
            newFormData.health_areas[area].markers[marker].details.meaning = r.data.normal_meaning
          }
          newFormData.health_areas[area].markers[marker].details.symptoms = ''
          newFormData.health_areas[area].markers[marker].details.actions = ''
          if (r.data.normal_repeat) {
            newFormData.health_areas[area].markers[marker].details.repeat = r.data.normal_repeat
          }
        }
        if (result === 'Abnormal high') {
          if (r.data.high_meaning) {
            newFormData.health_areas[area].markers[marker].details.meaning = r.data.high_meaning
          }
          if (r.data.high_symptoms) {
            newFormData.health_areas[area].markers[marker].details.symptoms = r.data.high_symptoms
          }
          if (r.data.high_actions) {
            newFormData.health_areas[area].markers[marker].details.actions = r.data.high_actions
          }
          if (r.data.high_repeat) {
            newFormData.health_areas[area].markers[marker].details.repeat = r.data.high_repeat
          }
        }
        if (result === 'positive') {
          if (r.data.positive_meaning) {
            newFormData.health_areas[area].markers[marker].details.meaning = r.data.positive_meaning
          }
          if (r.data.positive_symptoms) {
            newFormData.health_areas[area].markers[marker].details.symptoms = r.data.positive_symptoms
          }
          if (r.data.positive_actions) {
            newFormData.health_areas[area].markers[marker].details.actions = r.data.positive_actions
          }
          newFormData.health_areas[area].markers[marker].details.repeat = ''
        }
        if (result === 'negative') {
          if (r.data.negative_meaning) {
            newFormData.health_areas[area].markers[marker].details.meaning = r.data.negative_meaning
          }
          newFormData.health_areas[area].markers[marker].details.symptoms = ''
          newFormData.health_areas[area].markers[marker].details.actions = ''
          newFormData.health_areas[area].markers[marker].details.repeat = ''
        }
        if (result === 'manual') {
          newFormData.health_areas[area].markers[marker].details.what_can = ''
          newFormData.health_areas[area].markers[marker].details.meaning = ''
          newFormData.health_areas[area].markers[marker].details.symptoms = ''
          newFormData.health_areas[area].markers[marker].details.actions = ''
          newFormData.health_areas[area].markers[marker].details.repeat = ''
        }
      } else {
        console.log('unable to get new text')
      }
      setUpdatingText(false)
    }
    if (field === 'what_is') {
      newFormData.health_areas[area].markers[marker].details.what_is = event.target.value
    }
    if (field === 'what_can') {
      newFormData.health_areas[area].markers[marker].details.what_can = event.target.value
    }
    if (field === 'meaning') {
      newFormData.health_areas[area].markers[marker].details.meaning = event.target.value
    }
    if (field === 'symptoms') {
      newFormData.health_areas[area].markers[marker].details.symptoms = event.target.value
    }
    if (field === 'actions') {
      newFormData.health_areas[area].markers[marker].details.actions = event.target.value
    }
    if (field === 'repeat') {
      newFormData.health_areas[area].markers[marker].details.repeat = event.target.value
    }
    setFormData(newFormData)
    //console.log(event.target.value);
  }

  const showField = field => {
    //console.log(field)
    if (field === '') return <div className="font-italic">No notes have been entered</div>
    else if (field === undefined) return <div className="font-italic">-</div>
    else if (field === null) return <div className="font-italic">-</div>
    else
      return (
        <pre style={{whiteSpace: 'break-spaces', fontSize: '100%', fontFamily: 'inherit', textAlign: 'justify'}}>
          {field}
        </pre>
      )
  }

  const showResultOptions = (type, decision_type, value, i, j, code) => {
    if (type === 'low-normal-high')
      if (decision_type === 'female-manual')
        return (
          <Input
            type="select"
            name={'marker_result' + i + '-' + j}
            id={'marker_result' + i + '-' + j}
            value={value}
            onChange={event => updateReport(event, 'marker_result', i, j, code)}
          >
            <option value="female-manual">Unselected</option>
            <option>Abnormal low</option>
            <option>Normal</option>
            <option>Abnormal high</option>
          </Input>
        )
      else
        return (
          <Input
            type="select"
            name={'marker_result' + i + '-' + j}
            id={'marker_result' + i + '-' + j}
            value={value}
            onChange={event => updateReport(event, 'marker_result', i, j, code)}
          >
            <option value="female-manual">Unselected</option>
            <option>Abnormal low</option>
            <option>Normal</option>
            <option>Abnormal high</option>
          </Input>
        )
  }

  return (
    <div>
      <Form onSubmit={submitForm}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classNames({active: activeTab === 'summary'})}
              onClick={() => {
                setActiveTab('summary')
              }}
            >
              Summary
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({active: activeTab === 'general'})}
              onClick={() => {
                setActiveTab('general')
              }}
            >
              General
            </NavLink>
          </NavItem>
          {formData.health_areas.map((item, i) => (
            <NavItem>
              <NavLink
                className={classNames({active: activeTab === 'area' + i})}
                onClick={() => {
                  setActiveTab('area' + i)
                }}
              >
                {item.area}
              </NavLink>
            </NavItem>
          ))}
          <div className="ml-auto">
            {showResult === '' ? <></> : <span className="mx-3">{showResult}</span>}
            {editable ? (
              <Button>
                Save Changes{' '}
                {savingChanges ? (
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                ) : (
                  <></>
                )}
              </Button>
            ) : (
              <></>
            )}
          </div>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="summary">
            <Row>
              <Col sm="12">
                <Card className="my-3">
                  <CardHeader className="fs-large font-weight-bold ">Biomarkers</CardHeader>
                  <CardBody>
                    {formData.health_areas.map((health_area, index) => (
                      <>
                        <div
                          key={index}
                          className="font-weight-normal text-capitalize text-center inline-block border rounded p-2 bg-secondary-2 mb-4"
                        >
                          {health_area?.area?.toLowerCase() === 'no group page' ? formData.title : health_area.area}
                        </div>
                        <Table responsive key={index}>
                          <thead>
                            <tr>
                              <th>Biomarker Name</th>
                              <th>Output</th>
                              <th>Result</th>
                              <th className="pl-5">Range</th>
                            </tr>
                          </thead>
                          <tbody>
                            {health_area?.markers?.map((marker, markerIndex) => (
                              <tr key={markerIndex}>
                                <th className="bio-marker-col font-weight-normal" scope="row">
                                  {marker.name}
                                </th>
                                <td
                                  className={`output-col ${
                                    health_area.area === 'Sexual Health' &&
                                    shPositive.includes(marker.value.toLowerCase())
                                      ? 'positive'
                                      : ''
                                  }`}
                                >
                                  <span className="text-capitalize">{`${
                                    marker.value !== null
                                      ? health_area.area === 'Sexual Health' && marker?.value?.includes('**')
                                        ? marker?.value?.split('**')[1]
                                        : marker.value
                                      : 0.0
                                  }`}</span>
                                  &nbsp;<span>{marker.units}</span>
                                </td>
                                <td
                                  className=" result-col"
                                  style={{
                                    color:
                                      marker.result.includes('low') || marker.result.includes('high') ? '#d85515' : '',
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {health_area.area !== 'Sexual Health'
                                    ? markerResult.includes(marker.result.toLowerCase())
                                      ? marker.result
                                      : '――――'
                                    : '――――'}
                                </td>
                                {health_area.area !== 'Sexual Health' && marker.upper_boundary === null ? (
                                  <td>
                                    <div className="range-unit-container">
                                      <div className="range-col">
                                        <div className="boundary">
                                          {marker.code === 'EGFR' || marker.code === 'HDL' ? '>' : '<'}
                                          {` ${
                                            marker.upper_boundary !== null ? marker.upper_boundary : marker.boundary
                                          } `}
                                        </div>
                                      </div>
                                      <div class="unit-col">{marker.units}</div>
                                    </div>
                                  </td>
                                ) : health_area.area !== 'Sexual Health' ? (
                                  <td>
                                    <div className="range-unit-container">
                                      <div className="range-col">
                                        <div className="start-range">{`${
                                          marker.lower_boundary !== null ? marker.lower_boundary : 0.0
                                        }`}</div>
                                        <div className="range-divider"></div>
                                        <div className="end-range">
                                          {' '}
                                          {`${
                                            marker.upper_boundary !== null ? marker.upper_boundary : marker.boundary
                                          } `}
                                        </div>
                                      </div>
                                      <div class="unit-col">{marker.units}</div>
                                    </div>
                                  </td>
                                ) : (
                                  <td className="pl-5">――――</td>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </>
                    ))}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="general">
            <Row>
              <Col sm="12">
                <Card className="my-3">
                  <CardHeader>General</CardHeader>
                  <CardBody>
                    <Table>
                      <tbody>
                        <tr>
                          <th scope="row">Test Kit</th>
                          <td>{formData.title}</td>
                        </tr>
                        <tr>
                          <th scope="row">Customer</th>
                          <td>{formData.customer}</td>
                        </tr>
                        <tr>
                          <th scope="row">Created</th>
                          <td>{format_dt_ago(formData.created_at)}</td>
                        </tr>
                        <tr>
                          <th scope="row">Updated</th>
                          <td>{format_dt_ago(formData.updated_at)}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <Card className="my-3">
                      <CardHeader>Overall Status</CardHeader>
                      <CardBody>
                        {editable ? (
                          <Input
                            type="select"
                            name="overall_status"
                            id="overall_status"
                            value={formData.overall_status}
                            onChange={event => updateReport(event, 'overall_status')}
                          >
                            <option></option>
                            <option value="good">Everything looks good</option>
                            <option value="borderline">Some borderline results</option>
                            <option value="require-attention">Some results require attention</option>
                          </Input>
                        ) : (
                          showField(formData.overall_status)
                        )}
                      </CardBody>
                    </Card>
                    <Card className="my-3">
                      <CardHeader>Doctors Letter</CardHeader>
                      <CardBody className="text-justify">
                        {updatingLetter ? (
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        ) : (
                          <></>
                        )}
                        {editable ? (
                          <Input
                            className="text-justify"
                            type="textarea"
                            name="doctors_letter"
                            id="doctors_letter"
                            value={formData.doctors_letter}
                            onChange={event => updateReport(event, 'doctors_letter')}
                            rows={15}
                          />
                        ) : (
                          showField(formData.doctors_letter)
                        )}
                      </CardBody>
                    </Card>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
          {formData.health_areas.map((item, i) => (
            <TabPane tabId={'area' + i}>
              <Row>
                <Col sm="12">
                  <Card className="my-3">
                    <CardHeader>{item.area}</CardHeader>
                    <CardBody>
                      <Card className="my-3">
                        <CardHeader>Group Status</CardHeader>
                        <CardBody>
                          {editable ? (
                            <Input
                              type="select"
                              name={'area_status' + i}
                              id={'area_status' + i}
                              value={item.area_status}
                              onChange={event => updateReport(event, 'area_status', i)}
                            >
                              <option></option>
                              <option value="good">Everything looks good</option>
                              <option value="borderline">Some borderline results</option>
                              <option value="require-attention">Some results require attention</option>
                            </Input>
                          ) : (
                            showField(item.area_status)
                          )}
                        </CardBody>
                      </Card>
                      {formData.health_areas.length === 1 || item.markers.length === 1 ? (
                        <></>
                      ) : (
                        <Card className="my-3">
                          <CardHeader>Group overview</CardHeader>
                          <CardBody>
                            {editable ? (
                              <Input
                                type="textarea"
                                name={'doctors_notes_' + i}
                                id={'doctors_notes_' + i}
                                value={item.doctors_notes}
                                onChange={event => updateReport(event, 'doctors_notes', i)}
                              />
                            ) : (
                              showField(item.doctors_notes)
                            )}
                          </CardBody>
                        </Card>
                      )}
                      <h5>Biomarkers:</h5>
                      {item.markers.map((marker, j) =>
                        restricted || marker.error ? (
                          <Card className="my-3">
                            {marker.error ? (
                              <CardHeader id={'marker' + i + '-' + j}>
                                {marker.code} - {marker.error}
                              </CardHeader>
                            ) : (
                              <CardHeader id={'marker' + i + '-' + j}>
                                {marker.code} -{' '}
                                {marker.result === 'manual' ||
                                marker.result === '' ||
                                marker.result === undefined ||
                                marker.result === null ? (
                                  <>No Result</>
                                ) : (
                                  <>Result Available</>
                                )}
                              </CardHeader>
                            )}
                          </Card>
                        ) : (
                          <Card className="my-3">
                            <CardHeader id={'marker' + i + '-' + j}>{marker.name} (click to expand)</CardHeader>
                            <UncontrolledCollapse toggler={'#marker' + i + '-' + j}>
                              <CardBody>
                                <Table>
                                  <tbody>
                                    <tr>
                                      <th scope="row">Biomarker</th>
                                      <td>{marker.name}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Code</th>
                                      <td>{marker.code}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Result</th>
                                      <td>
                                        {editable &&
                                        (marker.decision_type === 'female-manual' ||
                                          marker.decision_type === 'bound-age')
                                          ? showResultOptions(
                                              marker.type,
                                              marker.decision_type,
                                              marker.result,
                                              i,
                                              j,
                                              marker.code,
                                            )
                                          : showField(marker.result)}
                                        {updatingText ? (
                                          <span
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        ) : (
                                          <></>
                                        )}
                                      </td>
                                    </tr>
                                    {/* 
                                    <tr>
                                      <th scope="row">Type</th>
                                      <td>{marker.type}</td>
                                    </tr>
                                    */}
                                    <tr>
                                      <th scope="row">Output</th>
                                      <td>
                                        {marker.value} {marker.units}
                                      </td>
                                    </tr>
                                    {marker.boundary ? (
                                      <tr>
                                        <th scope="row">Boundary</th>
                                        <td>
                                          {marker.boundary} {marker.units}
                                        </td>
                                      </tr>
                                    ) : (
                                      <></>
                                    )}
                                    {marker.lower_boundary ? (
                                      <tr>
                                        <th scope="row">Lower Boundary</th>
                                        <td>
                                          {marker.lower_boundary} {marker.units}
                                        </td>
                                      </tr>
                                    ) : (
                                      <></>
                                    )}
                                    {marker.upper_boundary ? (
                                      <tr>
                                        <th scope="row">Upper Boundary</th>
                                        <td>
                                          {marker.upper_boundary} {marker.units}
                                        </td>
                                      </tr>
                                    ) : (
                                      <></>
                                    )}
                                    <tr>
                                      <th scope="row">What is?</th>
                                      <td>
                                        {editable ? (
                                          <Input
                                            type="textarea"
                                            name={'marker_details_what_is_' + i + '_' + j}
                                            id={'marker_details_what_is_' + i + '_' + j}
                                            value={marker.details.what_is}
                                            onChange={event => updateReport(event, 'what_is', i, j)}
                                            readOnly={true}
                                          />
                                        ) : (
                                          showField(marker.details.what_is)
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">What can?</th>
                                      <td>
                                        {editable ? (
                                          <Input
                                            type="textarea"
                                            name={'marker_details_what_can_' + i + '_' + j}
                                            id={'marker_details_what_can_' + i + '_' + j}
                                            value={
                                              (marker.code === 'TESTO' || marker.code === 'SHBG') &&
                                              marker.result === 'low'
                                                ? ''
                                                : marker.details.what_can
                                            }
                                            onChange={event => updateReport(event, 'what_can', i, j)}
                                            readOnly={true}
                                          />
                                        ) : (
                                          showField(marker.details.what_can)
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Meaning</th>
                                      <td>
                                        {editable ? (
                                          <Input
                                            type="textarea"
                                            name={'marker_details_meaning_' + i + '_' + j}
                                            id={'marker_details_meaning_' + i + '_' + j}
                                            value={
                                              (marker.code === 'TESTO' || marker.code === 'SHBG') &&
                                              marker.result === 'low'
                                                ? ''
                                                : marker.details.meaning
                                            }
                                            onChange={event => updateReport(event, 'meaning', i, j)}
                                            readOnly={true}
                                          />
                                        ) : (
                                          showField(marker.details.meaning)
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Symptoms</th>
                                      <td>
                                        {editable ? (
                                          <Input
                                            type="textarea"
                                            name={'marker_details_symptoms_' + i + '_' + j}
                                            id={'marker_details_symptoms_' + i + '_' + j}
                                            value={
                                              (marker.code === 'TESTO' || marker.code === 'SHBG') &&
                                              marker.result === 'low'
                                                ? ''
                                                : marker.details.symptoms
                                            }
                                            onChange={event => updateReport(event, 'symptoms', i, j)}
                                            readOnly={true}
                                          />
                                        ) : (
                                          showField(marker.details.symptoms)
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Actions</th>
                                      <td>
                                        {editable ? (
                                          <Input
                                            type="textarea"
                                            name={'marker_details_actions_' + i + '_' + j}
                                            id={'marker_details_actions_' + i + '_' + j}
                                            value={
                                              (marker.code === 'TESTO' || marker.code === 'SHBG') &&
                                              marker.result === 'low'
                                                ? ''
                                                : marker.details.actions
                                            }
                                            onChange={event => updateReport(event, 'actions', i, j)}
                                            readOnly={true}
                                          />
                                        ) : (
                                          showField(marker.details.actions)
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Repeat</th>
                                      <td>
                                        {editable ? (
                                          <Input
                                            type="textarea"
                                            name={'marker_details_repeat_' + i + '_' + j}
                                            id={'marker_details_repeat_' + i + '_' + j}
                                            value={marker.details.repeat}
                                            onChange={event => updateReport(event, 'repeat', i, j)}
                                            readOnly={true}
                                          />
                                        ) : (
                                          showField(marker.details.repeat)
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </CardBody>
                            </UncontrolledCollapse>
                          </Card>
                        ),
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          ))}
        </TabContent>
      </Form>
    </div>
  )
}

export function prompt_download(download_ref, download_filename) {
  const a = document.createElement('a')
  a.href = download_ref
  a.download = download_filename || 'Download'
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export const stringAsArrayBuffer = v =>
  Uint8Array.from(atob(v.replace(/_/g, '/').replace(/-/g, '+')), c => c.charCodeAt(0))
export const arrayBufferAsBase64 = ab => btoa(String.fromCharCode(...new Uint8Array(ab)))

export function prepareWebauthn(credentials) {
  const {attestationObject, clientDataJSON, signature, authenticatorData} = credentials.response
  const data = {
    id: credentials.id,
    rawId: arrayBufferAsBase64(credentials.rawId),
    response: {
      attestationObject: arrayBufferAsBase64(attestationObject),
      clientDataJSON: arrayBufferAsBase64(clientDataJSON),
    },
  }
  if (signature) {
    data.response.signature = arrayBufferAsBase64(signature)
    data.response.authenticatorData = arrayBufferAsBase64(authenticatorData)
  }
  return data
}

// Get icon by test name
export const iconHelper = name => {
  let icon = null
  const iconList = [
    // DNA
    Glucose,
    Heart,
    Intolerances,
    Vitamins,
    Weight,

    // Blood
    Cholesterol,
    Energy_Profile,
    Male_Sexual_Health,
    General_Health_Test,
    Heart_Profile,
    Female_Sexual_Health,
    Menopause,
    PCOS,
    Thyroid,
    Erectile_Dysfunction,
    Vitamins_and_Minerals,
    Weight_Management,

    // Biomarkers
    Biomarker_Cholesterol,
    Biomarker_Full_Blood_Count,
    Biomarker_Hormones,
    Biomarker_Kidney,
    Biomarker_Liver,
    Biomarker_Sexual_Health,
    Biomarker_Thyroid,
    Biomarker_Vitamins_and_Minerals,
  ]

  const testNames = [
    // DNA
    'Glucose',
    'Heart',
    'Intolerances',
    'Vitamins',
    'Weight',

    // Blood
    'Cholesterol Profile Test',
    'Energy Profile',
    'Male Sexual Health',
    'General Health',
    'Heart Profile',
    'Female Sexual Health',
    'Menopause',
    'PCOS',
    'Thyroid',
    'Erectile Dysfunction',
    'Vitamins & Minerals Profile Test',
    'Weight Management',

    // Biomarkers
    'Biomarker Cholesterol',
    'Biomarker Full Blood Count',
    'Biomarker Hormones',
    'Biomarker Kidney',
    'Biomarker Liver',
    'Biomarker Sexual Health',
    'Biomarker Thyroid',
    'Biomarker Vitamins and Minerals',
  ]
  for (let i = 0; i < iconList.length; i++) {
    if (String(name).toLowerCase().includes(testNames[i].toLowerCase())) {
      icon = iconList[i]
    }
  }
  return icon
}
