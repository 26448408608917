import React from 'react'
import {useLocation, useHistory} from 'react-router-dom'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'

const ONE_HOUR_MS = 3600 * 1000

export default ({user}) => {
  const [showMfaModal, setShowMfaModal] = React.useState(false)
  const {pathname} = useLocation()
  const history = useHistory()

  const mfa_prompt = user?.mfa_otp_enabled === false && user?.mfa_webauthn_enabled === false

  React.useEffect(() => {
    if (mfa_prompt && !pathname.startsWith('/account/')) {
      const since_deferred = Date.now() - parseInt(localStorage.mfa_deferred || '0')
      if (since_deferred > ONE_HOUR_MS) {
        setShowMfaModal(true)
      }
    }
  }, [mfa_prompt, pathname])

  const deferMfa = () => {
    setShowMfaModal(false)
    localStorage.mfa_deferred = Date.now()
  }

  const onEnable = () => {
    setShowMfaModal(false)
    localStorage.removeItem('mfa_deferred')
    history.push('/account/')
  }

  return (
    <Modal isOpen={showMfaModal} toggle={() => setShowMfaModal(false)}>
      <ModalHeader toggle={() => setShowMfaModal(false)}>Enable MFA</ModalHeader>
      <ModalBody>
        <p>
          Multi-factor Authentication (also known as Two Factor authentication or 2FA) improves your account security
          by requiring a second means of authentication when logging in.
        </p>
        <p>You should enable MFA for LIMS as soon as possible.</p>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={deferMfa}>
          Enable Later
        </Button>
        <Button color="primary" onClick={onEnable} className="ml-2">
          Enable Now
        </Button>
      </ModalFooter>
    </Modal>
  )
}
