import React from 'react'
import {Loading} from 'reactstrap-toolbox'
import Buttons from 'src/shared/Buttons'
import Details from 'src/shared/Details'

export default () => {
  const [data, setData] = React.useState(null)
  const [updateValue, Update] = React.useState(0)

  React.useEffect(() => {
    window.app.setTitle('Account')
    window.app.requests.get('/api/lims/account/').then(r => setData(r.data))
  }, [updateValue])

  if (!data) {
    return <Loading />
  }
  const {item, buttons, details} = data
  return (
    <div>
      <h1>Account Details</h1>
      <Buttons buttons={buttons || []} update={() => Update(u => u + 1)} />

      <div>
        <Details details={details} item={item} />
      </div>
    </div>
  )
}
