import React from 'react'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'

function DoctorLetterModal({isOpen, toggle, title, children}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} scrollable centered>
      <ModalHeader toggle={toggle}>
        <strong>{title}</strong>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export {DoctorLetterModal}
