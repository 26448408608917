import React, {useState} from 'react'
import {faCheck, faChevronRight, faExclamation} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Collapse} from 'reactstrap'

import {HealthAreaSection} from './HealthAreaSection'

import './MobileHealthArea.scss'

function MobileHealthArea({expanded = false, collapsible = true, ...props}) {
  const [isOpen, setIsOpen] = useState(expanded)

  const toggle = () => setIsOpen(!isOpen)

  const statusIcon = props.area_status === 'good' ? faCheck : faExclamation

  if (!collapsible) {
    return <HealthAreaSection {...props} hideHeader collapseItems={false} />
  }

  return (
    <div className={`mobileHealthArea__container ${isOpen && 'is-opened'}`}>
      <button className="mobileHealthArea__header" onClick={toggle}>
        <h3 className="mobileHealthArea__header-title">
          <span className={`mobileHealthArea__header-status mobileHealthArea__header-status--${props.area_status}`}>
            <FontAwesomeIcon icon={statusIcon} />
          </span>
          {props.area}
        </h3>
        <div className="mobileHealthArea__toggle">
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </button>
      <Collapse isOpen={isOpen}>
        <div className="mobileHealthArea__main">
          <HealthAreaSection {...props} hideTitle hideIcon collapseItems />
        </div>
      </Collapse>
    </div>
  )
}
export default MobileHealthArea
