import React from 'react'

const endpoint = process.env.REACT_APP_API_ENDPOINT || 'https://api-staging.myhealthchecked.com'
export default () => {
  if (process.env.NODE_ENV === 'development') {
    return (
      <div className="flag">
        dev mode, endpoint: <a href={endpoint}>{endpoint}</a>
      </div>
    )
  } else if (process.env.REACT_APP_STAGING) {
    return (
      <div className="flag">
        {process.env.REACT_APP_STAGING}, endpoint: <a href={endpoint}>{endpoint}</a>
        &nbsp;<a href="/info.json">deploy info</a>
      </div>
    )
  } else {
    return null
  }
}
