import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {Error as ErrorComponent, Loading} from 'reactstrap-toolbox'
import Login from 'src/views/Login'
import Logout from 'src/views/Logout'
import ResetPasswordRequest from 'src/views/ResetPasswordRequest'
import ResetPasswordForm from 'src/views/ResetPasswordForm'
import EnableOtp from 'src/views/EnableOtp'
import EnableWebauthn from 'src/views/EnableWebauthn'
import ConfirmMfa from 'src/views/ConfirmMfa'
import Account from 'src/views/Account'
import Dashboard from 'src/views/Dashboard'
import List from 'src/views/List'
import Details from 'src/views/Details'
import AntigenNext from 'src/views/AntigenNext'

import {Narrow} from './NarrowWide'
import ReportNext from 'src/views/ReportNext'
import BloodReportPage from 'src/views/BloodReports/BloodReportPage'
import NotFound from './NotFound'

const NarrowError = Narrow(ErrorComponent)
const NarrowLoading = Narrow(Loading)

export default ({error, user, clearMenu, panels}) => {
  if (error) {
    return <NarrowError error={error} />
  } else if (user === null) {
    // user request has not yet finished, wait for it to avoid waiting logic in all the components below
    return <NarrowLoading />
  }
  const AuthNarrow = (Component, props = {}) => Narrow(Component, props, user)

  return (
    <Switch>
      <Route exact path="/login/" render={Narrow(Login, {user})} />
      <Route exact path="/login/mfa/:blob/" render={Narrow(ConfirmMfa, {user})} />
      <Route exact path="/password-reset/" render={Narrow(ResetPasswordRequest)} />
      <Route exact path="/password-reset/:blob/" render={Narrow(ResetPasswordForm, {auth_user: user})} />
      <Route exact path="/review-reports/report/:id/" render={AuthNarrow(BloodReportPage, {user})} />

      <Route exact path="/" render={AuthNarrow(Dashboard, {panels})} />
      <Route exact path="/account/" render={AuthNarrow(Account)} />
      <Route exact path="/account/enable-otp/" render={AuthNarrow(EnableOtp, {user})} />
      <Route exact path="/account/enable-webauthn/" render={AuthNarrow(EnableWebauthn, {user})} />
      <Route exact path="/logout/" render={AuthNarrow(Logout, {clearMenu})} />

      <Route exact path="/antigen/next/" render={AuthNarrow(AntigenNext)} />
      <Route exact path="/gp-view/next/" render={AuthNarrow(ReportNext)} />
      <Route exact path="/:view/" render={AuthNarrow(List)} />
      <Route exact path="/:view/:id(\d+)/" render={AuthNarrow(Details)} />
      <Route exact path="/:view/:subview/:id(\d+)/" render={AuthNarrow(Details)} />
      <Route exact path="/:view/:subview/:id(\d+)/:marker/" render={AuthNarrow(Details)} />

      <Route render={Narrow(NotFound)} />
    </Switch>
  )
}
