import React from 'react'
import {ListGroup, ListGroupItem as BsListGroupItem, Collapse, Navbar, NavbarToggler} from 'reactstrap'
import {Link, useLocation} from 'react-router-dom'
import {as_title} from 'reactstrap-toolbox'

const ListGroupItem = ({className, disabled, to, active, title, borderBottom}) => (
  <BsListGroupItem
    tag={Link}
    to={to}
    active={active}
    className={`${disabled ? 'disabled-menu-item' : className || ''} ${borderBottom ? 'menu-border-bottom' : ''}`}
  >
    <div>{title}</div>
  </BsListGroupItem>
)

const overlap = (path, link) => {
  for (let i = 1; i < path.length; i++) {
    if (path.substring(0, i) !== link.substr(0, i)) {
      return i - 1
    }
  }
}

const get_selected = (menu, location) => {
  const pathname = location.pathname
  for (let item of menu) {
    if (item.to === pathname) {
      return item.to
    }
  }
  let best = 0
  let selected = null
  for (let item of menu) {
    const ov = overlap(pathname, item.to)
    if (ov > best) {
      best = ov
      selected = item.to
    }
  }
  return selected
}

export default ({className, menu}) => {
  const location = useLocation()
  let menu_ = []
  if (menu) {
    menu_ = menu
  }
  const selected = get_selected(menu_, location)
  const [isOpen, setIsOpen] = React.useState(false)
  const path = location.pathname

  React.useEffect(() => {
    setIsOpen(false)
  }, [path])

  const toggle = () => setIsOpen(!isOpen)
  return (
    <Navbar light expand="lg" className={`menu justify-content-end ${className} p-0`}>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <ListGroup className="flex-grow-1 menu text-nowrap p-0">
          {menu_.map(m => (
            <ListGroupItem
              borderBottom={m.borderBottom}
              className={m.className}
              disabled={m.disabled}
              key={m.to}
              to={m.to}
              active={m.to === selected}
              title={as_title(m.name)}
            />
          ))}
        </ListGroup>
      </Collapse>
    </Navbar>
  )
}
