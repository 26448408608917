import React from 'react'
import {useHistory} from 'react-router-dom'
import {Alert} from 'reactstrap'
import {faQrcode} from '@fortawesome/free-solid-svg-icons'
import {Loading, message_toast, StandaloneForm} from 'reactstrap-toolbox'

const fields = {
  code: {
    required: true,
    max_length: 10,
    autocomplete: 'off',
    focus: true,
    help_text:
      "Once you've scanned the QR code, enter the 6 digit code displayed to confirm you have the MFA stored successfully.",
  },
}

export default ({user}) => {
  const [dataUri, setDataUrl] = React.useState(null)
  const history = useHistory()
  const endpoint = '/api/lims/account/enable-otp/'

  React.useEffect(() => {
    window.app.setTitle('Enable App MFA')
    window.app.requests.get(endpoint).then(r => setDataUrl(r.data.qr_data_uri))
  }, [])

  const afterSubmit = () => {
    message_toast({
      icon: faQrcode,
      title: 'MFA Enabled',
      message: 'App MFA successfully enabled',
      progress: false,
      time: 2000,
    })
    window.app.reAuthenticate()
    history.push('/account/')
  }

  if (!dataUri) {
    return <Loading />
  } else {
    return (
      <div className="row justify-content-center pt-4">
        <div className="col-md-6">
          <h2>Enable App Multi-factor Authentication (MFA)</h2>
          {user.mfa_otp_enabled ? (
            <Alert color="info">
              <b>Note:</b> app MFA is already enabled on your account, completing this form will generate a new app MFA
              token, old app MFA tokens will cease to work.
            </Alert>
          ) : null}
          <div className="text-muted">
            <p>
              Multi-factor Authentication (also known as Two Factor authentication or 2FA) improves your account
              security by requiring a second means of authentication when logging in.
            </p>
            <p>Scan the QR code below using one of these app:</p>
            <ul>
              <li>
                <b>Google Authenticator</b> for <Link platform="Android" app="ga" /> or{' '}
                <Link platform="iPhone" app="ga" />
              </li>
              <li>
                <b>Authy</b> for <Link platform="Android" app="authy" /> or <Link platform="iPhone" app="authy" />
              </li>
            </ul>
          </div>
          <div className="d-flex justify-content-center pt-2">
            <img src={dataUri} className="rounded qr-image border" alt="MFA QR code" width="300" height="300" />
          </div>

          <StandaloneForm fields={fields} endpoint={endpoint} afterSubmit={afterSubmit} save_label="Enable MFA" />
        </div>
      </div>
    )
  }
}

const Link = ({platform, app}) => (
  <a href={links[platform][app]} target="_blank" rel="noopener noreferrer">
    {platform}
  </a>
)

const links = {
  Android: {
    ga: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_GB&gl=US',
    authy: 'https://play.google.com/store/apps/details?id=com.authy.authy&hl=en_GB&gl=US',
  },
  iPhone: {
    ga: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
    authy: 'https://apps.apple.com/us/app/twilio-authy/id494168017',
  },
}
