import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {ButtonGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {faEdit} from '@fortawesome/free-solid-svg-icons'
import ReactMarkdown from 'react-markdown'
import {as_title, Form, Loading, message_toast} from 'reactstrap-toolbox'
import {field_title, format_val, prompt_download} from 'src/utils'

export default ({buttons, update, className = 'text-right'}) => {
  const [button_id, setButton] = React.useState(null)

  if (!buttons?.length) {
    return null
  }
  const button = buttons[button_id] || {}
  return (
    <div className={className}>
      <ButtonGroup>
        {buttons.map((b, i) => (
          <CustomButton key={i} button={b} index={i} setButton={setButton} />
        ))}
      </ButtonGroup>
      <ModalForm button={button} toggle_modal={() => setButton(null)} update={update} />
    </div>
  )
}

const CustomButton = ({button, index, setButton}) => {
  const {title, link, color, goto} = button
  const text = title || as_title(link)
  const button_color = color || 'primary'
  if (goto) {
    return (
      <Button tag={Link} color={button_color} to={goto}>
        {text}
      </Button>
    )
  } else {
    return (
      <Button color={button_color} onClick={() => setButton(index)}>
        {text}
      </Button>
    )
  }
}

const barcode_regex = /^\s*[a-z]{2}\d{8}\s*$/i

const ModalForm = ({button, toggle_modal, update}) => {
  const [data, setData] = React.useState(null)
  const [formData, setFormData] = React.useState({})
  const [formattedData, setFormattedData] = React.useState({})
  const submitButtonRef = React.createRef()

  const {title, endpoint} = button

  React.useEffect(() => {
    if (endpoint) {
      window.app.requests.get(endpoint).then(r => setData(r.data))
    }
  }, [endpoint])

  let barcode = null
  if (endpoint === '/api/lims/tests/mark/received/') {
    barcode = formData.barcode
  }

  React.useEffect(() => {
    if (barcode && barcode.match(barcode_regex) && submitButtonRef.current) {
      submitButtonRef.current.click()
    }
  }, [barcode, submitButtonRef])

  const close = () => {
    setData(null)
    setFormData({})
    update && update()
    toggle_modal()
  }

  // Calculating the time offset
  function splitDateTimeAndTimezone(datetimeString) {
    if (datetimeString) {
      // Extract date and time without timezone offset
      const [datePart, timePart] = datetimeString.split('T')
      const dateWithoutOffset = datePart
      const timeWithoutOffset = timePart.split(/[+-]/)[0]
      const time1 = `${dateWithoutOffset}T${timeWithoutOffset}`
      const offset = new Date(Date.parse(time1)).getTimezoneOffset()

      // Extract timezone offset if present
      const pad2 = number => Math.abs(number).toString().padStart(2, '0')

      // NOTE: getTimezoneOffset is the opposite way round from normal TZ offsets, e.g. in BST getTimezoneOffset() gives -60
      const display_offset = offset =>
        `${offset > 0 ? '-' : '+'}${pad2(Math.floor(Math.abs(offset) / 60))}:${pad2(Math.abs(offset) % 60)}`

      return `${dateWithoutOffset}T${timeWithoutOffset + display_offset(offset)} `
    }
  }

  useEffect(() => {
    setFormattedData({})
    let time = splitDateTimeAndTimezone(formData.attempt_moment)
    time &&
      setFormattedData({
        attempt_moment: time.trim(),
        attempt_type: formData.attempt_type,
        comments: formData.comments,
        outcome: formData.outcome,
      })
    // eslint-disable-next-line
  }, [formData])

  const afterSubmit = r => {
    setFormData({})
    const {message, next, redirect, download_href, download_filename} = r.data
    if (message) {
      message_toast({icon: faEdit, title, message})
    }

    if (next) {
      window.app.requests.get(next).then(r => setData(r.data))
    } else {
      close()

      if (download_href) {
        prompt_download(download_href, download_filename)
      }

      if (redirect) {
        setTimeout(() => {
          window.location = redirect
        }, 400)
      } else {
        update && update()
      }
    }
  }

  const open = !!endpoint
  if (!data) {
    return (
      <Modal isOpen={open} toggle={close}>
        <ModalHeader toggle={close}>{title}</ModalHeader>
        <ModalBody>
          <Loading />
        </ModalBody>
        <ModalFooter>
          <Button onClick={close}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }

  const {fields, initial, submit_initial, post_endpoint, save_label, cancel_label} = data
  return (
    <Modal size="xl" isOpen={open} toggle={close}>
      <ModalHeader toggle={close}>{title}</ModalHeader>
      <Form
        form_data={title === 'Add Consultation Notes' ? formattedData : formData}
        onChange={setFormData}
        fields={fields || {}}
        initial={initial}
        submit_initial={submit_initial || false}
        endpoint={post_endpoint || endpoint}
        afterSubmit={afterSubmit}
        onCancel={close}
        save_label={save_label || 'Save'}
        cancel_label={cancel_label || 'Cancel'}
        form_body_class="modal-body"
        form_footer_class="modal-footer"
        submitButtonRef={submitButtonRef}
        allow_empty
      >
        <>
          <ReactMarkdown>{data.prefix}</ReactMarkdown>
          {data.item ? (
            <div>
              {data.details.map((col, i) => (
                <div key={i} className="detail">
                  <div>{field_title(col)}:</div>
                  <div>{format_val(data.item, col)}</div>
                </div>
              ))}
            </div>
          ) : null}
        </>
      </Form>
    </Modal>
  )
}
