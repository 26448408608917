import React, {useEffect} from 'react'
import {as_title} from 'reactstrap-toolbox'
import Table from 'src/shared/Table'

export default ({match}) => {
  const {view} = match.params
  useEffect(() => {
    window.app.setTitle(as_title(view))
  })

  return (
    <div>
      <Table endpoint={`/api/lims/${view}/`} buttons={true} view_name={view} striped breadcrumbs />
    </div>
  )
}
