import React from 'react'
import {Loading} from 'reactstrap-toolbox'
import Panels from './Panels'

export default ({panels}) => {
  if (!panels) {
    return <Loading />
  } else {
    return (
      <div className="top">
        <Panels panels={panels} />
      </div>
    )
  }
}
