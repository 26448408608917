import React from 'react'
import {Spinner} from 'reactstrap'

export default ({url, children, className}) => {
  const [downloading, setDownloading] = React.useState(false)

  const download = async e => {
    e.preventDefault()
    if (downloading) {
      return
    }
    setDownloading(true)
    const r = await fetch(e.target.href)
    if (!r.ok) {
      window.app.setError(`Download Failed ${r.status}`)
    } else {
      const a = document.createElement('a')
      a.href = URL.createObjectURL(await r.blob())
      a.download = r.headers.get('Content-Disposition').match(/filename="(.*?)"/)[1]
      document.body.appendChild(a)
      a.click()
      a.remove()
    }
    setDownloading(false)
  }

  return (
    <a href={url} onClick={download} className={className}>
      {children} {downloading && <Spinner color="secondary" size="sm" />}
    </a>
  )
}
