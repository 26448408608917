import React from 'react'
import {Link, Redirect, useLocation, useHistory} from 'react-router-dom'
import {message_toast, on_mobile, Form} from 'reactstrap-toolbox'
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons'
import PublicNotice from 'src/shared/PublicNotice'

const BeforeButtons = () => (
  <div>
    <Link to="/password-reset/">Reset Password</Link>
  </div>
)

export default ({user}) => {
  const location = useLocation()
  const history = useHistory()
  const params = new URLSearchParams(location.search)
  const [next_url, setNext_url] = React.useState(params.get('next'))
  const [recaptcha, setRecaptcha] = React.useState(false)
  const [formData, setFormData] = React.useState({})

  React.useEffect(() => {
    window.app.setTitle('Login')
    window.app.requests.get('/api/lims/login/').then(r => setRecaptcha(r.data.recaptcha_required))
  }, [])

  const afterSubmit = r => {
    sessionStorage.setItem('isLoggedIn', true)
    if (r.data?.redirect) {
      setNext_url(r.data.redirect)
    }
    if (r.data?.details?.location) {
      const url = `mfa/${r.data.details.location}`
      if (next_url) {
        history.replace(`${url}?next=${encodeURIComponent(next_url)}`)
      } else {
        history.replace(url)
      }
    } else {
      if (!on_mobile) {
        message_toast({
          icon: faSignInAlt,
          title: 'Logged in',
          message: 'Logged in successfully',
          progress: false,
          time: 2000,
        })
      }
      window.app.reAuthenticate()
    }
  }

  const execute = async data => {
    const r = await window.app.requests.post('/api/lims/login/', data, {expected_status: [200, 310, 422]})
    if (r.status === 422) {
      setRecaptcha(true)
      setFormData({email: formData.email || null})
    }
    return r
  }

  if (user) {
    return <Redirect to={next_url || '/'} push={false} />
  }

  const fields = {
    email: {required: true, type: 'email', max_length: 127, autocomplete: 'username'},
    password: {required: true, type: 'password', max_length: 31, autocomplete: 'current-password'},
  }
  if (recaptcha) {
    fields.recaptcha_token = {required: true, type: 'recaptcha'}
  }

  return (
    <div>
      <div className="row justify-content-center pt-4">
        <div className="col-md-8">
          <h1>MyHealthChecked LIMS</h1>
          <div className="text-muted">
            <PublicNotice />
            {next_url ? <p>Please login to view</p> : null}
          </div>
          <Form
            fields={fields}
            afterSubmit={afterSubmit}
            execute={execute}
            form_data={formData}
            onChange={setFormData}
            save_label="Log in"
            BeforeButtons={BeforeButtons}
          />
        </div>
      </div>
    </div>
  )
}
