import React from 'react'
import {Link, useLocation, useHistory} from 'react-router-dom'
import {InputGroup, InputGroupAddon, Button, Input, ListGroup, ListGroupItem, Badge} from 'reactstrap'
import {faSearch, faBackspace} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import useDebounce from 'src/shared/useDebounce'
import {as_title} from 'reactstrap-toolbox/lib/utils'

export default ({className}) => {
  const [searchQuery, setSearchQuery] = React.useState('')
  const [results, setResults] = React.useState(null)
  const [selected, setSelected] = React.useState(0)
  const boxRef = React.useRef()
  const {pathname} = useLocation()
  const history = useHistory()

  const debouncedSearchQuery = useDebounce(searchQuery, 300)

  const clear = React.useCallback(() => {
    setSearchQuery('')
    setResults(null)
    setSelected(0)
  }, [])

  const searchCallback = React.useCallback(
    search => {
      if (!search) {
        clear()
      } else {
        window.app.requests.get('/api/lims/search/', {search}).then(r => setResults(r.data.items))
      }
    },
    [clear],
  )

  const onKeyDown = e => {
    const {key} = e
    if (key === 'ArrowDown') {
      setSelected(v => Math.min(v + 1, results.length - 1))
    } else if (key === 'ArrowUp') {
      setSelected(v => Math.max(v - 1, 0))
    } else if (key === 'Enter') {
      go()
    }
  }

  const go = () => {
    const selected_item = results && results[selected]
    if (selected_item) {
      history.push(selected_item.link)
    }
  }

  React.useEffect(() => {
    searchCallback(debouncedSearchQuery)
  }, [searchCallback, debouncedSearchQuery])

  React.useEffect(() => {
    clear()
  }, [pathname, clear])

  return (
    <div id="search" ref={boxRef} className={className}>
      <InputGroup id="search-input">
        <Input
          placeholder="search..."
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          onKeyDown={onKeyDown}
        />
        <InputGroupAddon addonType="append">
          <Button onClick={clear} title="clear">
            <FontAwesomeIcon icon={faBackspace} />
          </Button>
          <Button onClick={go} title="search">
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroupAddon>
      </InputGroup>

      <Results results={results} selected={selected} boxRef={boxRef} />
    </div>
  )
}

const Results = ({results, selected, boxRef}) => {
  const [transform, setTransform] = React.useState(null)
  const {pathname} = useLocation()

  React.useEffect(() => {
    setTimeout(() => {
      const search_el = boxRef.current
      if (search_el) {
        const {offsetLeft, offsetTop, offsetHeight} = search_el
        const left = offsetLeft
        const top = offsetTop + offsetHeight + 5
        setTransform(`translate3d(${left}px, ${top}px, 0)`)
      }
    }, 50)
  }, [boxRef, pathname])

  if (!results) {
    // not searching
    return null
  } else if (results.length) {
    // results found
    return (
      <ListGroup className="search-list" style={{transform}}>
        {results.map(({link, label, badge}, i) => (
          <ListGroupItem key={i} tag={Link} to={link} active={i === selected} action disabled={link === pathname}>
            {label} {link === pathname ? '[current page]' : null}
            <Badge className="float-right px-2">{as_title(badge)}</Badge>
          </ListGroupItem>
        ))}
      </ListGroup>
    )
  } else {
    // searching, but no results found
    return (
      <ListGroup className="search-list" style={{transform}}>
        <ListGroupItem>
          <small className="text-muted">(no results)</small>
        </ListGroupItem>
      </ListGroup>
    )
  }
}
