import React from 'react'
import {Redirect} from 'react-router-dom'
import {Alert} from 'reactstrap'
import {as_title, Loading} from 'reactstrap-toolbox'
import ReactMarkdown from 'react-markdown'
import Breadcrumbs from 'src/shared/Breadcrumbs'
import {sub_keys} from 'src/utils'
import Table from 'src/shared/Table'
import Buttons from 'src/shared/Buttons'
import DownloadLink from 'src/shared/DownloadLink'
import Details from 'src/shared/Details'
import CassettePhoto from 'src/shared/CassettePhoto'
import DetailForm from 'src/shared/DetailForm'

const table_props = (t, item) => ({
  ...t,
  endpoint: sub_keys(t.endpoint, item),
  page_key: t.title.toLowerCase().replace(' ', '-'),
})

const button_props = (btn, item) => ({
  ...btn,
  endpoint: btn.endpoint && sub_keys(btn.endpoint, item),
  goto: btn.goto && sub_keys(btn.goto, item),
})

const MarkdownAlert = ({alert}) => {
  if (!alert) {
    return null
  }
  const {color, title, message} = alert
  return (
    <div className="row justify-content-end">
      <div className="col-md-6">
        <Alert color={color} className="mb-0">
          {title && <h3 className="h5">{title}</h3>}
          <ReactMarkdown>{message}</ReactMarkdown>
        </Alert>
      </div>
    </div>
  )
}

export default ({match}) => {
  const [data, setData] = React.useState(null)
  const [redirect, setRedirect] = React.useState(null)
  const [updateValue, setUpdateVal] = React.useState(0)

  const {view, subview, id, marker} = match.params
  const endpoint = subview ? `${view}/${subview}` : view

  React.useEffect(() => {
    window.scrollTo(0, 0)
    window.app.requests.get(`/api/lims/${endpoint}/${id}/`, null, {expected_status: [200, 310]}).then(r => {
      if (r.status === 310) {
        setRedirect(r.data.details.location)
      } else {
        setData(r.data)
        const item = r.data.item
        if (item) {
          const name = item.name || item.booking_reference || item.id.toString()
          window.app.setTitle(`${as_title(view)} / ${name}`)
        }
      }
    })
  }, [view, endpoint, id, updateValue])

  const update = () => setUpdateVal(u => u + 1)

  if (redirect) {
    return <Redirect to={redirect} push={false} />
  } else if (!data) {
    return <Loading />
  }
  const {item, buttons, details, sub_tables, download, cassette_image, form, alert} = data
  const name = item.name || item.booking_reference || item.id.toString()
  return (
    <div>
      <Breadcrumbs items={[view, subview, name, marker]} />
      <Buttons buttons={(buttons || []).map(b => button_props(b, item))} update={update} />
      {download && (
        <DownloadLink url={sub_keys(download, item)} className="my-2 d-block">
          Download {as_title(view)}
        </DownloadLink>
      )}

      <MarkdownAlert alert={alert} />
      <div>
        <div className="detail">
          <div>ID:</div>
          <div>{item.id}</div>
        </div>
        <Details details={details} item={item} />
      </div>

      {cassette_image ? <CassettePhoto src={cassette_image} /> : null}
      {form ? <DetailForm form={form} update={update} /> : null}

      {sub_tables ? (
        <div className="mt-3">
          {sub_tables.map((t, i) => (
            <div key={i}>
              <h2>{t.title || as_title(t.view)}</h2>
              <Table {...table_props(t, item)} updateValue={updateValue} />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}
