import React from 'react'
import {Redirect} from 'react-router-dom'
import {Loading} from 'reactstrap-toolbox'

export default () => {
  const [loaded, setLoaded] = React.useState(false)
  const [redirect, setRedirect] = React.useState(null)

  React.useEffect(() => {
    let clear = 0
    const check = async () => {
      const r = await window.app.requests.get('/api/lims/antigen/next/')
      if (r.data.test_id) {
        clearInterval(clear)
        setRedirect(`/antigen/${r.data.test_id}/`)
        return true
      } else {
        setLoaded(true)
      }
    }
    check().then(success => {
      if (!success) {
        clear = setInterval(check, 2000)
      }
    })
    return () => clearInterval(clear)
  }, [])

  return redirect ? (
    <Redirect to={redirect} push={false} />
  ) : (
    <Loading>
      {loaded ? 'No tests currently pending, this page will reload when a test requires evaluation.' : null}
    </Loading>
  )
}
