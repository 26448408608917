import React from 'react'
import {Redirect, useLocation} from 'react-router-dom'
import {Loading, message_toast, on_mobile, StandaloneForm} from 'reactstrap-toolbox'
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons'
import {prepareWebauthn, stringAsArrayBuffer} from 'src/utils'
import {Button} from 'reactstrap'

export default ({match, user}) => {
  const [mode, setMode] = React.useState(null)
  const [allowSwitch, setAllowSwitch] = React.useState(false)
  // use JSON here to avoid faffing with react missing dependencies or objects changing all the time
  const [publicKeyJson, setPublicKeyJson] = React.useState(null)
  const location = useLocation()
  const {blob} = match.params
  const params = new URLSearchParams(location.search)
  const next_url = params.get('next')

  React.useEffect(() => {
    window.app.setTitle('Confirm MFA')
    window.app.requests.get(`/api/lims/confirm-mfa/${blob}/`).then(r => {
      if (r.data.webauthn_options) {
        setPublicKeyJson(JSON.stringify(r.data.webauthn_options))
        if (r.data.otp) {
          setAllowSwitch(true)
        }
        setMode('webauthn')
      } else {
        setMode('otp')
      }
    })
  }, [blob])

  const afterSubmit = React.useCallback(() => {
    if (!on_mobile) {
      message_toast({
        icon: faSignInAlt,
        title: 'Logged in',
        message: 'Logged in successfully',
        progress: false,
        time: 2000,
      })
    }
    window.app.reAuthenticate()
  }, [])

  if (!mode) {
    return <Loading />
  } else if (user) {
    return <Redirect to={next_url || '/'} push={false} />
  } else if (mode === 'webauthn') {
    return (
      <WebauthnForm
        publicKeyJson={publicKeyJson}
        afterSubmit={afterSubmit}
        blob={blob}
        allowSwitch={allowSwitch}
        setMode={setMode}
      />
    )
  } else {
    return <OtpForm afterSubmit={afterSubmit} blob={blob} allowSwitch={allowSwitch} setMode={setMode} />
  }
}

const WebauthnForm = ({publicKeyJson, blob, afterSubmit, allowSwitch, setMode}) => {
  const [pageError, setPageError] = React.useState(null)

  const confirm = React.useCallback(async () => {
    setPageError(null)
    const publicKey = JSON.parse(publicKeyJson)
    publicKey.challenge = stringAsArrayBuffer(publicKey.challenge)
    publicKey.allowCredentials[0].id = stringAsArrayBuffer(publicKey.allowCredentials[0].id)
    let creds
    try {
      creds = await navigator.credentials.get({publicKey})
    } catch (err) {
      console.warn('navigator.credentials.get refused:', err)
      setPageError('Hardware MFA refused to authenticate, please try again')
      return
    }
    const r = await window.app.requests.post(`/api/lims/confirm-mfa/${blob}/webauthn/`, prepareWebauthn(creds), {
      expected_status: [200, 403],
    })
    if (r.status === 200) {
      afterSubmit()
    } else {
      setPageError(`Hardware MFA device error: ${r.data.message}`)
    }
  }, [publicKeyJson, afterSubmit, blob])

  React.useEffect(() => {
    confirm()
  }, [confirm])

  return (
    <div className="row justify-content-center pt-4">
      <div className="col-md-8">
        <Loading />
        <div className="text-center my-3 text-muted">
          <div>Use your MFA device to authenticate.</div>
          {pageError ? (
            <div className="mt-2">
              <p className="text-danger">{pageError}</p>
              <Button color="primary" onClick={confirm}>
                Try Again
              </Button>
              <hr />
            </div>
          ) : null}
          {allowSwitch ? (
            <Button size="sm" color="link" onClick={() => setMode('otp')}>
              Use App MFA instead
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const fields = {
  code: {
    required: true,
    max_length: 10,
    autocomplete: 'off',
    help_text: '6 digit code generated by your MFA App',
    focus: true,
  },
}

const OtpForm = ({blob, afterSubmit, allowSwitch, setMode}) => (
  <div className="row justify-content-center pt-4">
    <div className="col-md-8">
      <h1>Confirm MFA</h1>
      <div className="text-muted">Please enter the code from your MFA/2FA App.</div>
      <StandaloneForm
        fields={fields}
        afterSubmit={afterSubmit}
        endpoint={`/api/lims/confirm-mfa/${blob}/otp/`}
        save_label="Log in"
      />
      <div className="text-right">
        {allowSwitch ? (
          <Button size="sm" color="link" onClick={() => setMode('webauthn')}>
            Use Hardware MFA instead
          </Button>
        ) : null}
      </div>
    </div>
  </div>
)
