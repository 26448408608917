import React from 'react'
import {Link} from 'react-router-dom'
import {Card, CardText, CardTitle, Col, Row} from 'reactstrap'

export default ({panels}) => (
  <Row>
    {panels.map(({title, value, description, link, color}, i) => (
      <Col key={i} md={4}>
        <Card body className="mb-2">
          <CardTitle tag="h5">{link ? <Link to={link}>{title}</Link> : title}</CardTitle>
          <CardText tag="div">
            <div className={`display-3 text-${color || 'dark'}`}>{value}</div>
            <p className="small">{description}</p>
          </CardText>
        </Card>
      </Col>
    ))}
  </Row>
)
