import React from 'react'

export default () => (
  <>
    <p>This system is for MyHealthChecked staff only.</p>
    <p>
      If you're a customer of MyHealthChecked, please login from our public website,{' '}
      <a href="https://myhealthchecked.com">myhealthchecked.com</a>.
    </p>
  </>
)
