import React from 'react'
import {Redirect} from 'react-router-dom'
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons'
import {Loading, message_toast, confirm_modal, on_mobile} from 'reactstrap-toolbox'

export default ({clearMenu}) => {
  const [redirect, setRedirect] = React.useState(null)

  React.useEffect(() => {
    const update = async () => {
      const logout = await confirm_modal({message: 'Are you sure you want to logout?', continue_text: 'Logout'})
      if (!logout) {
        setRedirect('/')
        return
      }
      await window.app.requests.post('/api/lims/logout/')
      if (!on_mobile) {
        message_toast({
          icon: faSignOutAlt,
          title: 'Logged out',
          message: 'Logged out successfully',
          progress: false,
          time: 2000,
        })
      }
      clearMenu()
      window.app.reAuthenticate()
    }
    update()
  }, [clearMenu])

  return redirect ? <Redirect to={redirect} push={false} /> : <Loading />
}
