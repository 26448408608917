import React from 'react'
import {useHistory} from 'react-router-dom'
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import ReactMarkdown from 'react-markdown'
import {message_toast, StandaloneForm} from 'reactstrap-toolbox'

export default ({form, update}) => {
  const history = useHistory()
  const {title, prefix, fields, post_endpoint, next_url, save_label, cancel_label} = form

  const afterSubmit = r => {
    message_toast({
      icon: faCheck,
      title: 'Submitted',
      message: r.data?.message || 'Form submitted successfully',
    })
    if (next_url) {
      history.push(next_url)
    } else {
      update()
    }
  }

  return (
    <div>
      <div className="row justify-content-center pt-4">
        <div className="col-md-8">
          <h3>{title}</h3>
          <ReactMarkdown>{prefix}</ReactMarkdown>
          <StandaloneForm
            fields={fields}
            afterSubmit={afterSubmit}
            endpoint={post_endpoint}
            save_label={save_label || 'Save'}
            cancel_label={cancel_label || 'Cancel'}
            // type_lookup={{colored_toggle: ColoredInputToggle}}
            allow_empty
          />
        </div>
      </div>
    </div>
  )
}
