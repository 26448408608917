import React from 'react'
import {Link, useLocation, useHistory} from 'react-router-dom'
import {Table, Nav, NavItem, NavLink, Badge} from 'reactstrap'
import {Loading, combine_classes, as_title} from 'reactstrap-toolbox'
import {field_title, format_val} from 'src/utils'
import DownloadLink from 'src/shared/DownloadLink'
import Buttons from './Buttons'
import ListModifiers from './ListModifiers'
import Breadcrumbs from 'src/shared/Breadcrumbs'

const PAGINATION = 50
var islessThan50 = false
var newParams = []
var url

export default ({
  page_key,
  endpoint,
  updateValue,
  view_name,
  breadcrumbs = false,
  buttons = false,
  striped = false,
}) => {
  const [data, setData] = React.useState(null)
  const [buttonUpdateValue, buttonUpdate] = React.useState(0)
  const [updating, setUpdating] = React.useState(true)

  const {search, pathname} = useLocation()
  const params = new URLSearchParams(search)
  const history = useHistory()
  const page = parseInt(params.get(page_key || 'page') || '1')
  if (page_key) {
    // we need to set page as a param for the request as the endpoint won't recognize page_key
    params.delete(page_key)
    params.set('page', page.toString())
  }
  const split = endpoint.includes('?') ? '&' : '?'
  const endpoint_full_url = endpoint + split + params.toString()

  React.useEffect(() => {
    setUpdating(true)
    window.app.requests.get(endpoint_full_url).then(r => {
      if (r.data.items.length === 0 && page !== 1) {
        // Redirect to page 1 if null is returned from api
        islessThan50 = true
        const splitPage = params.toString().split(/&?page=\d+&?/)
        newParams = splitPage[1] !== '' ? splitPage[1] : splitPage[0]
        url = newParams !== '' || newParams !== 'undefined' ? `${pathname}?${newParams}` : `${pathname}`
        history.push(`${url}`)
      } else {
        setUpdating(false)
        setData(r.data)
      }
    })
    // eslint-disable-next-line
  }, [endpoint_full_url, pathname, buttonUpdateValue, updateValue])

  if (!data) {
    return (
      <>
        <Breadcrumbs items={[view_name]} />
        <Loading />
      </>
    )
  } else {
    view_name = data.title || view_name
    return (
      <div>
        {breadcrumbs ? <Breadcrumbs items={[view_name]} /> : null}
        {buttons && <Buttons buttons={data.buttons} update={() => buttonUpdate(u => u + 1)} />}
        <ListModifiers modifiers={data.modifiers} />
        {updating ? (
          <Loading />
        ) : (
          <DataTable
            data={data}
            striped={striped}
            page={page}
            page_key={page_key}
            view_name={view_name || page_key}
            islessThan50={islessThan50}
          />
        )}
      </div>
    )
  }
}

const Pagination = ({page, max_pages, page_key, islessThan50}) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  if (max_pages === 1) {
    return null
  }
  const page_url = p => {
    if (p === 1 || islessThan50) {
      params.set(page_key, p)
    } else {
      params.set(page_key, p)
      // Add or modify your other parameters here
      for (const [key, value] of params.entries()) {
        params.set(key, value)
      }
    }
    return `${location.pathname}?${params.toString()}`
  }
  const buttons_count = Math.min(max_pages, 10)
  const start = Math.max(1, Math.min(page - 5, max_pages - 9))
  const pages = [...Array(buttons_count)].map((_, i) => i + start)
  return (
    <div className="d-flex justify-content-center">
      <nav>
        <ul className="pagination">
          <li className={combine_classes('page-item', page === 1 ? 'disabled' : null)}>
            <Link className="page-link" to={page_url(page - 1)}>
              Previous
            </Link>
          </li>
          {pages.map(p => (
            <li key={p} className={combine_classes('page-item', page === p ? 'active' : null)}>
              <Link className="page-link" to={page_url(p)}>
                {p}
              </Link>
            </li>
          ))}
          <li className={combine_classes('page-item', page >= max_pages ? 'disabled' : null)}>
            <Link className="page-link" to={page_url(page + 1)}>
              Next
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

const DataTable = ({data, striped, page, page_key, view_name, islessThan50}) => {
  const {tabs, cols, items, empty_message, count} = data
  return (
    <>
      {tabs ? <Tabs tabs={tabs} /> : <TableSummary data={data} page={page} view_name={view_name} />}
      <Table className="mt-2" striped={striped}>
        <thead>
          <tr>
            {cols.map((col, i) => (
              <th key={i}>{field_title(col)}</th>
            ))}
            {items.some(item => item.export_url) && <th>Download</th>}
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr key={item.id}>
              {cols.map((col, i) => (
                <td key={i}>{format_val(item, col)}</td>
              ))}
              {item.export_url && (
                <td>
                  <DownloadLink url={item.export_url}>Download</DownloadLink>
                </td>
              )}
            </tr>
          ))}
        </tbody>
        {items.length === 0 ? (
          <caption className="text-center mt-4">
            {empty_message || <>No {as_title(view_name || 'items')} to display</>}
          </caption>
        ) : null}
      </Table>
      <Pagination
        page={page}
        max_pages={Math.floor(count / PAGINATION) + 1}
        page_key={page_key || 'page'}
        islessThan50={islessThan50}
      />
    </>
  )
}

const Tabs = ({tabs}) => {
  const {search} = useLocation()
  const params = new URLSearchParams(search)
  const active_tab = params.get('tab') || tabs[0].key
  return (
    <Nav tabs>
      {tabs.map(({key, label, count}) => (
        <NavItem key={key}>
          <NavLink className={key === active_tab ? 'active' : ''} tag={Link} to={`?tab=${key}`}>
            {label}
            {Number.isInteger(count) ? (
              <Badge color="primary" className="ml-1 px-2">
                {count}
              </Badge>
            ) : null}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

const TableSummary = ({data, page, view_name}) => {
  const {export_url, count} = data
  const {search} = useLocation()
  const title = as_title(view_name || 'items')

  if (export_url) {
    return (
      <aside>
        <div>
          <DownloadLink url={export_url + search}>
            Export {count} {title}
          </DownloadLink>
        </div>
        {page === 1 ? null : <b>page {page}</b>}
      </aside>
    )
  } else {
    if (page === 1) {
      return (
        <aside>
          {count} {title}
        </aside>
      )
    } else {
      return (
        <aside>
          {count} {title}, <b>page {page}</b>
        </aside>
      )
    }
  }
}
