import React from 'react'
import {useHistory} from 'react-router-dom'
import {Input, CustomInput, InputGroup, InputGroupAddon, Button} from 'reactstrap'
import {as_title} from 'reactstrap-toolbox'
import useDebounce from './useDebounce'

const Search = ({modifier, title, value, onChange}) => {
  const [text, setText] = React.useState(value)

  const debouncedText = useDebounce(text, 500)

  React.useEffect(() => {
    onChange(debouncedText)
  }, [onChange, debouncedText])

  let {key} = modifier
  return (
    <InputGroup>
      <Input id={key} name={key} value={text} onChange={e => setText(e.target.value)} placeholder={title} />
      <InputGroupAddon addonType="append">
        <Button onClick={() => setText('')}>Clear</Button>
      </InputGroupAddon>
    </InputGroup>
  )
}

// from reactstrap-toolbox/forms/Input.js
function prep_choice(c) {
  if (typeof c === 'string') {
    c = {value: c}
  }
  if (typeof c !== 'object' || typeof c.value !== 'string') {
    throw TypeError(`choice not an object or choice.value not a string: ${c}`)
  }
  return {value: c.value, label: c.label || as_title(c.value)}
}

const Choices = ({modifier, value, onChange}) => {
  const {key, choices} = modifier
  return (
    <CustomInput type="select" id={key} name={key} value={value} onChange={e => onChange(e.target.value)}>
      {choices.map(prep_choice).map((c, i) => (
        <option key={i} value={c.value}>
          {c.label}
        </option>
      ))}
    </CustomInput>
  )
}

const Modifier = ({modifier, width}) => {
  const history = useHistory()

  let {key, title, choices} = modifier

  const onChange = React.useCallback(
    value => {
      const p = new URLSearchParams(history.location.search)
      if (value) {
        p.set(key, value)
      } else {
        p.delete(key)
      }
      history.push(history.location.pathname + '?' + p.toString())
    },
    [history, key],
  )

  const params = new URLSearchParams(history.location.search)
  title = title || as_title(key)
  const InputComponent = choices ? Choices : Search
  return (
    <div className={`col-md-${width}`}>
      <div className="text-muted small">{title}</div>
      <InputComponent modifier={modifier} title={title} value={params.get(key) || ''} onChange={onChange} />
    </div>
  )
}

export default ({modifiers}) => {
  if (!modifiers || !modifiers.length) {
    return null
  }
  const width = modifiers.length < 4 ? 4 : 3
  return (
    <div className="row my-3 justify-content-end">
      {modifiers.map(m => (
        <Modifier key={m.key} modifier={m} width={width} />
      ))}
    </div>
  )
}
