import React from 'react'

export default ({src}) => {
  const imgRef = React.useRef()
  const [xy, setXY] = React.useState([-1, -1])
  const [zoomIndex, setZoomIndex] = React.useState(0)

  return (
    <div className="my-3">
      <div className="my-1 d-flex justify-content-center">
        <img
          src={src}
          ref={imgRef}
          alt="Cassette"
          className="img-fluid rounded cassette-image mx-1"
          onMouseMove={e => setXY([e.clientX, e.clientY])}
          onClick={() => setZoomIndex(s => s + 1)}
          onMouseLeave={() => setXY([-1, -1])}
          crossOrigin
        />
        <div>
          <PreviewCanvas image={imgRef.current} xy={xy} zoomIndex={zoomIndex} />
        </div>
      </div>
      <div className="text-center text-muted">(Click to zoom in further on the image)</div>
    </div>
  )
}

const PreviewCanvas = ({image, xy, zoomIndex}) => {
  const canvasRef = React.useRef()
  const contextRef = React.useRef(null)
  const [x, y] = xy
  const size = 300

  React.useEffect(() => {
    canvasRef.current.width = size
    canvasRef.current.height = size
    contextRef.current = canvasRef.current.getContext('2d')
  }, [])

  React.useEffect(() => {
    if (!contextRef.current || !image) {
      return
    }
    const {naturalWidth, naturalHeight} = image
    const {top, left, width, height} = image.getBoundingClientRect()
    const img_x = ((x - left) * naturalWidth) / width
    const img_y = ((y - top) * naturalHeight) / height

    const ctx = contextRef.current
    if (x === -1 && y === -1) {
      ctx.clearRect(0, 0, size, size)
      return
    }
    const zoom_levels = [1, 1.5, 2, 3]
    const source_size = size / zoom_levels[zoomIndex % zoom_levels.length]
    ctx.drawImage(
      image,
      bounds(img_x - source_size / 2, 0, naturalWidth - source_size),
      bounds(img_y - source_size / 2, 0, naturalHeight - source_size),
      source_size,
      source_size,
      0,
      0,
      size,
      size,
    )
  }, [x, y, zoomIndex, image])

  const style = {width: `${size}px`, height: `${size}px`}
  return <canvas ref={canvasRef} className="rounded border cassette-canvas mx-1" style={style} />
}

const bounds = (v, min_value, max_value) => Math.max(Math.min(v, max_value), min_value)
