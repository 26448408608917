import React from 'react'
import {Link} from 'react-router-dom'
import {Breadcrumb, BreadcrumbItem} from 'reactstrap'
import {as_title} from 'reactstrap-toolbox'

export default ({items}) => (
  <Breadcrumb>
    <BreadcrumbItem>
      <Link to={`/${items[0]}/`}>{as_title(items[0])}</Link>
    </BreadcrumbItem>
    {items
      .filter((item, i) => !!item && i > 0)
      .map((item, i) => (
        <BreadcrumbItem key={i}>{as_title(item)}</BreadcrumbItem>
      ))}
  </Breadcrumb>
)
