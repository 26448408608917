import React from 'react'
import {useHistory} from 'react-router-dom'
import {Alert, Button} from 'reactstrap'
import {faQrcode} from '@fortawesome/free-solid-svg-icons'
import {Loading, message_toast} from 'reactstrap-toolbox'
import {prepareWebauthn, stringAsArrayBuffer} from 'src/utils'

export default ({user}) => {
  const [publicKey, setPublicKey] = React.useState(null)
  const [pageError, setPageError] = React.useState(null)
  const history = useHistory()
  const endpoint = '/api/lims/account/enable-webauthn/'

  React.useEffect(() => {
    window.app.setTitle('Enable Hardware MFA')
    window.app.requests.get(endpoint).then(r => {
      const pk = r.data
      pk.user.id = stringAsArrayBuffer(pk.user.id)
      pk.challenge = stringAsArrayBuffer(pk.challenge)
      setPublicKey(pk)
    })
  }, [])

  const enable = async () => {
    let creds
    try {
      creds = await navigator.credentials.create({publicKey})
    } catch (err) {
      console.warn('navigator.credentials.create refused:', err)
      setPageError(`Error: ${err.toString()}`)
      return
    }
    const r = await window.app.requests.post(endpoint, prepareWebauthn(creds), {expected_status: [200, 403]})
    if (r.status === 200) {
      message_toast({
        icon: faQrcode,
        title: 'MFA Enabled',
        message: 'Hardware MFA successfully enabled',
        progress: false,
        time: 2000,
      })
      window.app.reAuthenticate()
      history.push('/account/')
    } else {
      setPageError(r.data.message)
    }
  }

  if (!publicKey) {
    return <Loading />
  } else {
    return (
      <div className="row justify-content-center pt-4">
        <div className="col-md-6">
          <h2>Enable Hardware Multi-factor Authentication (MFA)</h2>
          {user.mfa_webauthn_enabled ? (
            <Alert color="info">
              <b>Note:</b> Hardware MFA is already enabled on your account, completing this form will generate a new
              hardware MFA token, old hardware MFA tokens will cease to work.
            </Alert>
          ) : null}
          <div className="text-muted">
            <p>
              Multi-factor Authentication (also known as Two Factor authentication or 2FA) improves your account
              security by requiring a second means of authentication when logging in.
            </p>
            <p>
              Authenticate with a U2F hardware device. This is a device like a Yubikey or something similar which
              supports FIDO's U2F specification. This also requires a browser which supports this system (like Google
              Chrome).
            </p>
          </div>
          <div className="text-center">
            <Button color="primary" onClick={enable}>
              Enable Hardware MFA
            </Button>
          </div>
          {pageError ? (
            <div className="mt-3 text-danger">
              <b>Hardware MFA device error:</b> {pageError}
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}
